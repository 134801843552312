<template>
    <div id="container">
        <label class="font-bold" style="font-size:18px" for="">Thêm file đối soát</label>
        <div class="vx-card p-6 mx-auto">
            <div class="vx-row mt-4">
                <div class="vx-col md:w-1/4 w-full">
                    <div class="w-full">
                        <span class="font-bold" style="color:#ccc">Bước 1: Chọn thời gian đối soát</span>
                    </div>
                    <vs-divider/>
                    <div class="w-full">
                        <label class="font-bold" for="">Đối soát từ ngày đến ngày</label>
                        <date-range-picker class="w-full" :locale-data="{ format: 'dd/mm/yyyy' }" :opens="'right'"
                            @update="dateRangePicker" v-model="dateRange">
                        </date-range-picker>
                    </div>

                    <div class="w-full mt-4">
                        <span class="font-bold" style="color:#ccc">Bước 2: Chọn loại đối soát</span>
                    </div>
                    <vs-divider/>

                    <div class="w-full mt-4">
                        <label class="font-bold" for="">Chọn file mẫu</label>
                        <v-select v-model="templateTypeModel" :options="templateOptions"></v-select>
                    </div>
                    <div class="w-full mt-4" v-if="templateTypeModel">
                        <label class="font-bold">Tải xuống file mẫu</label>
                        <div class="w-full">
                            <img class="cursor-pointer" style="height:40px" src="../../../assets/images/logo/excel_file.png"
                            alt="File_Example" @click="downloadFileEx">
                        </div>
                    </div>
                </div>
                <div class="vx-col md:w-3/4 w-full" v-if="templateTypeModel">
                    <div class="w-full">
                        <span class="font-bold" style="color:#ccc">Bước 3: Tải lên file đối soát</span>
                    </div>
                    <vs-divider/>
                    <label class="font-bold" for="">Chọn file đối soát</label>
                    <div class="w-full">
                        <div class="vx-col">
                            <input type="file" ref="fileInput" class="hidden w-full" accept=".xlsx, .xls, .xlsm" @change="handleClick">
                            <div @click="$refs.fileInput.click()" @drop="handleDrop" @dragover="handleDragover"
                                @dragenter="handleDragover"
                                class="import-file px-3 py-2 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl w-full">
                                <feather-icon v-if="this.uploadSuccess == false" icon="UploadCloudIcon"
                                    svgClasses="h-10 w-10 stroke-current text-grey" class="block mt-5" />
                                <img v-if="this.uploadSuccess == true" class="cursor-pointer" style="height:60px"
                                    src="../../../assets/images/logo/excel_file.png" alt="File_Example">
                                <!-- <span>Kéo thả file vào đây hoặc bấm để chọn </span> -->
                                <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap">
                <vs-button class="ml-auto" :disabled="isValid" @click="saveFileCompare">Thêm File</vs-button>
                <vs-button icon-pack="feather" icon="icon-arrow-left" class="ml-4" type="border" color="primary" @click="$router.push('/user/transaction-history')">Quay lại</vs-button>
            </div>
        </div>
    </div>
</template>

<script>

import DateRangePicker from '../../../components/vue2-daterange-picker/components/DateRangePicker.vue'
import XLSX from 'xlsx'
import vSelect from 'vue-select';
import authConfig from '@/../auth_config.json';

export default {
    data() {
        return {
            isValid: false,
            templateOptions: [
                {label: 'Đối soát tài khoản', value: 1, templateName: 'template_user', templateType: 1, sheetName: 'Tài khoản', fileName: ''},
                {label: 'Đối soát gói cước', value: 2, templateName: 'template_package', templateType: 2, sheetName: 'Gói cước', fileName: ''},
                {label: 'Đối soát thanh toán', value: 5, templateName: 'template_payment', templateType: 5, sheetName: 'Thanh toán', fileName: ''},
            ],
            dateRange: {
                startDate: new Date(),
                endDate: (() => {
                    let date = new Date();
                    date.setDate(date.getDate() + 6);
                    date.setHours(0, 0, 0);
                    return date;
                })(),
            },
            excelData: {
                header: null,
                results: null,
                meta: null,
            },
            uploadSuccess: false,
            selectTemplateType : null,
            templateInfo: {}
        }
    },

    components: {
        DateRangePicker, vSelect
    },
    computed: {
        templateTypeModel: {
            get(){
                let object = this.templateOptions.find(el => el.value == this.selectTemplateType);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(obj) {
                this.selectTemplateType = obj ? obj.value : null;
                this.templateInfo = obj;
                if (this.selectTemplateType == 1) this.templateOptions[0].fileName = "Đối_soát_tài_khoản"
                if (this.selectTemplateType == 2) this.templateOptions[1].fileName = "Đối_soát_gói_dịch_vụ"
                if (this.selectTemplateType == 5) this.templateOptions[2].fileName = "Đối_soát_thanh_toán"
            }
        }
    },

    methods: {
        dateRangePicker: function () {
            this.dateRange.startDate = new Date(
                this.dateRange.startDate.setHours(0, 0, 0, 0)
            );
            this.dateRange.endDate = new Date(
                this.dateRange.endDate.setHours(23, 59, 59, 0)
            );
        },

        downloadFileEx() { 
            const payload = {
                indexSheet: 0,
                pathName: this.templateInfo.templateName,
                templateName: this.templateInfo.label
            }
            this.$crm.post('/template/download-file', payload).then((response) => {
                 window.open(authConfig.crmDomain + "file/" + response.data + "?access_token=" + localStorage.getItem("access_token"), "_blank");
            })
        },

        saveFileCompare() {
            this.dateRangePicker();
            this.templateInfo.fromDate = this.dateRange.startDate;
            this.templateInfo.toDate = this.dateRange.endDate;
            this.$vs.loading();
            this.$crm.post('/template/export-compare', this.templateInfo).then((res) => {
                this.$router.push("/user/reconcile-list-history");
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
            })
        },

        handleDragover(e) {
            e.stopPropagation()
            e.preventDefault()
            e.dataTransfer.dropEffect = 'copy'
        },
        handleDrop(e) {
            e.stopPropagation()
            e.preventDefault()
            const files = e.dataTransfer.files
            if (files.length !== 1) {
                this.$vs.notify({
                    title: 'Login Attempt',
                    text: 'Only support uploading one file!',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                })
                return
            }
            const rawFile = files[0] // only use files[0]
            if (!this.isExcel(rawFile)) {
                this.$vs.notify({
                    title: 'Login Attempt',
                    text: 'Only supports upload .xlsx, .xls, .csv, .xlsm suffix files',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                })
                return false
            }
            this.uploadFile(rawFile)
        },

        handleClick(e) {
            const files = e.target.files
            const rawFile = files[0]
            if (!rawFile) return
            this.uploadFile(rawFile)
        },
        isExcel(file) {
            return /\.(xlsx|xls|csv|xlsm)$/.test(file.name)
        },
        uploadFile(file) {
            this.$refs['fileInput'].value = null // fix can't select the same excel
            this.uploadSuccess = true
            this.readerData(file)
        },

        readerData(rawFile) {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = e => {
                    const data = e.target.result
                    const workbook = XLSX.read(data, { type: 'array' })
                    const firstSheetName = workbook.SheetNames[0]
                    const worksheet = workbook.Sheets[firstSheetName]
                    const header = this.getHeaderRow(worksheet)
                    const results = XLSX.utils.sheet_to_json(worksheet)
                    const meta = { sheetName: firstSheetName }
                    this.generateData({ header, results, meta })
                    resolve()
                }
                reader.readAsArrayBuffer(rawFile)
            })
        },

        generateData({ header, results, meta }) {
            let filterHeader = header.filter(el => !el.includes("UNKNOW"));
            let headerUser = "id,username,paymentDate";
            let headerPackage = "id,packageName,cameraName,paymentDate,amount";
            let headerPayment = "orderId,transId,payType,amount,paymentDate"
            let data = results.slice(1, results.length);
            let validData = true;
            if (this.templateInfo.value == 1) {
                data.forEach(el => {
                    if (el.username) el.username = this.splitData(el.username.trim());
                    if (el.paymentDate) el.paymentDate = this.splitData(el.paymentDate.trim());
                    if (!el.id || !el.username || !el.paymentDate) validData = false;
                })
                if (!filterHeader.toString().includes(headerUser) || data.length == 0 || !validData) {
                    this.isValid = true;
                    return this.$vs.notify({
                        title: 'Đối soát dữ liệu',
                        color: 'danger',
                        text: 'File đối soát không hợp lệ !',
                        iconPack: 'feather',
                        icon: 'StopIcon',
                    });
                }
                this.isValid = false;
                this.templateInfo.compareUserDTOS = data.map(el => {
                    el.paymentDate = new Date(el.paymentDate.split('/').reverse().join('/'))
                    return el;
                });
            } 
            if (this.templateInfo.value == 2) {
                data.forEach(el => {
                    if (isNaN(el.id)) validData = false;
                    if (el.packageName) el.packageName = this.splitData(el.packageName.trim());
                    if (el.paymentDate) el.paymentDate = this.splitData(el.paymentDate.trim());
                    if (el.cameraName) el.cameraName = this.splitData(el.cameraName.trim());
                    if (el.amount && isNaN(el.amount)) el.amount = parseInt(this.splitData(el.amount.trim()));
                    if (!el.id || (isNaN(el.amount) || (el.amount == null || el.amount == undefined)) || !el.packageName || !el.paymentDate || !el.cameraName) {
                        validData = false;
                    }
                })
                data.forEach(el => console.log(el.amount))
                if (!filterHeader.toString().includes(headerPackage) || data.length == 0 || !validData) {
                    this.isValid = true;
                    return this.$vs.notify({
                        title: 'Đối soát dữ liệu',
                        color: 'danger',
                        text: 'File đối soát không hợp lệ !',
                        iconPack: 'feather',
                        icon: 'StopIcon',
                    });
                }
                this.isValid = false;
                this.templateInfo.comparePackageDTOS = data.map(el => {
                    el.paymentDate = new Date(el.paymentDate.split('/').reverse().join('/'));
                    return el;
                });
            }
            if (this.templateInfo.value == 5) {
                data.forEach(el => {
                    if (el.orderId) el.orderId = this.splitData(el.orderId.trim());
                    if (el.transId) el.transId = this.splitData(el.transId.trim());
                    if (el.payType) el.payType = this.splitData(el.payType.trim());
                    if (el.paymentDate) el.paymentDate = this.splitData(el.paymentDate.trim());
                    if (!el.orderId || !el.transId || !el.payType || !el.amount || !el.paymentDate) validData = false;
                })
                if (!filterHeader.toString().includes(headerPayment) || data.length == 0 || !validData) {
                    this.isValid = true;
                    return this.$vs.notify({
                        title: 'Đối soát dữ liệu',
                        color: 'danger',
                        text: 'File đối soát không hợp lệ !',
                        iconPack: 'feather',
                        icon: 'StopIcon',
                    });
                }
                this.isValid = false;
                this.templateInfo.comparePaymentDTOS = data.map(el => {
                    el.paymentDate = new Date(el.paymentDate.split('/').reverse().join('/'));
                    return el;
                });
            }
            this.excelData.header = header
            this.excelData.results = results
            this.excelData.meta = meta
            this.onSuccess && this.onSuccess(this.excelData)
        },
        getHeaderRow(sheet) {
            const headers = []
            const range = XLSX.utils.decode_range(sheet['!ref'])
            let C
            const R = range.s.r
            /* start in the first row */
            for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
                const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
                /* find the cell in the first row */
                let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
                if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
                headers.push(hdr)
            }
            return headers
        },
        splitData(data){
            return data.replaceAll("\t", "").replaceAll(",", "");
        }


    }
}
</script>

<style>
.float-right {
    text-align: end;
    align-items: center;
}

.label-input {
    font-size: 16px;
    font-weight: bold;
}

.import-file {
    height: 80px;
    width: 80px;
}
</style>